import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { LegalNoteDialog } from '../../components/LegalNoteDialog'
import { useCoin } from '../../context/coin_context'
import { SaleInfo } from '../../context/sale_context'
import { getSaleById } from '../../services/sale'

export function RenderNote() {
    const [searchParams] = useSearchParams()
    const { coins } = useCoin()
    const [sales, setSales] = useState<SaleInfo[]>([])

    useEffect(() => {
        if (!coins.length) return
        const saleId = searchParams.get('saleId')
        if (saleId) handleLoadData(Number(saleId))
    }, [coins])

    const handleLoadData = async (saleId: number) => {
        const sale = await getSaleById(saleId, coins)
        setSales([sale])
    }

    if (!sales.length) return <></>

    return <LegalNoteDialog sales={sales} handleClose={() => {}} dontPrint />
}
