import jwtDecode from 'jwt-decode'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'

function handleLoginFromParams() {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const token = params.get('token')
    const cashierHash = params.get('cashierHash')

    if (token) {
        const { iat } = jwtDecode<any>(token)
        const timeDiff = Math.floor(new Date().valueOf() / 1000 - Number(iat))
        localStorage.setItem('token', token)
        localStorage.setItem('timeDiff', timeDiff.toString())
    }
    if (cashierHash) localStorage.setItem('cashierHash', cashierHash)
}

handleLoginFromParams()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
)
